/* .modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); 
    display: none;
    z-index: 999; 
  }
  
  .modal-overlay.active {
    display: block;
  } */

  .modal-overlay {
    position: fixed;
    /* top: 0;
    left: 0; */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
    display: none;
    z-index: 999; /* Ensure the overlay is on top of other content */
  }
  
  .modal-overlay.active {
    display: block;
  }
