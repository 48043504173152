
.loader-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    opacity: 0.7;
    z-index: 222;
}
.loader {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border-top: 3px solid #ffffff;
    animation: rotate 850ms linear infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  