body{
  transition: all 300ms;
}
.section-home{
    height: 100vh;
}

.sticky-fix{
position: fixed;
top: 0;
width: 100%;
max-width: 500px;
}



.container-nav-bar{
    color: black;
    font-weight: bold;
    background-color: white;
    position: relative;
    /* border: 2px solid black; */
}


.container-nav-bar .nav-bar {
    display: flex;
    justify-content: center;
    align-items: center ;
    height: 72px;
}

.nav-bar .logo-div{
  width: 4rem;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1rem;
}
.nav-bar .hamburger {
    position: absolute;
    left: 0;
    top: 2;
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.nav-bar .hamburger:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}
.nav-bar .hamburger .ham-1{
    width: 17px;
    height: 1px;
    background-color: black;
    margin: 1.7px 0;
}
.points-class{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px;
}
img {
    height: auto;
    width: 100%;
}
.button.is-rounded {
    border-radius: 290486px;
    padding-left: calc(1em + 0.25em);
    padding-right: calc(1em + 0.25em);
}
.button.is-primary {
    background-color: #FAFF00;
    color: black;
    padding: 0.8rem 1.6rem;
    font-weight: bold;
}
.img-section{
    position: relative;
}
.txt-on-img {
    position: absolute;
    bottom: 25px;
    width: 100%;
}
.txt-on-img h5{
    margin-bottom: 20px;
}
.menu-list {
  display: grid;
  grid-template-columns: repeat(2, 40%); /* 2 columns */
  grid-gap: 1.5rem; /* You can adjust this to achieve the desired gap */
  padding: 0 1rem;
  justify-content: center;
  margin-top: 3.5rem;
}

.menu-list .list-items {
  height: 18vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 16px;
  box-shadow: 1px 1px 10px 1px rgb(176, 160, 128);
}

.menu-list .list-items img {
  height: 4.2rem;
  width: 4.2rem;
}

.menu-list .list-items figure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.txt-on-img h5{
    font-size: 2rem;
    font-weight: 600;
}
@media screen and (min-width: 425px) {
    .section-home {
        height: 100%;
      padding-bottom: 3rem;
    }
  }

/* menu .nav-bar  side bar*/
  .menu-bar{
    position: absolute;
    z-index: 2;
    height:100vh;
    background-color: #9D328D;
    width: 0;
    height: 100vh;
    transition: all 300ms linear;
    top: 0;
    overflow-x: hidden;
    font-weight: bold;
  }
  /* .menu-bar-width {
    width: 70%;
  }  */
  .active {
    width: 70%;
  }

  .menu-bar .link-tags{
    display:flex ;
    flex-direction: column ;
    justify-content: space-around ;
    color: white;
    line-height: 2;
    font-size: 16px;
    /* width: 320px; */
  }

  .menu-bar .link-tags > *{
    color: #000000;
    padding-left:0.6em;
    padding-right:0.6em;
  }
  .menu-bar-slide-hide{
    width: 0;
    display: none;

  }



/* .nav-link-single:hover{
    background-color: #930f7f;
    width: 200%;
    color: white;
} */
.sidebar-heading{
    padding-left: 0.6em;
    /* margin-top : 2em; */
    color:#930f7f;
    font-weight: 600;
}

.sidebar-heading-title{
  color: black;
  font-weight: bold;
  font-size: larger;
}
  /* close button */

  .close {
    position: absolute;
    display: flex;
    flex-direction: row-reverse;
    top: 12px;
    right: 12px;
    width: 25px;
    height: 25px;
    background-color: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 2px;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    opacity: 1;
    position: absolute;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: rgb(255, 255, 255);
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }

  /* arrow code */

  .back-arrow{
    width: 100%;

  }
  .back-arrow-button{
    display: flex;
    flex-direction: row;
    width: 3em;
    padding-left: 20px;
  }
  .back-arrow-button:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }

  .arrow {
    border: solid black;
    width: 1em;
    height: 1em;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 3px;
    font-size: 16px;

  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  /* hosted page css */

  .header-hosted-page{
    display: flex;
    font-size: 25px;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    position: absolute;

}

  iframe{
    width: 100%;
    height: 100vh;
    border: none;
    background-color: white;
  }

  .button.is-primary.is-rounded.is-uppercase {
    width: fit-content;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.loader-btn{
  height: 15px;
  width: 15px;
  margin: auto;
  border-radius: 50%;
  border-top: 2px solid #000000;
  animation: rotate 600ms linear infinite;
}
.loader-btn.align-loader-btn {
  margin: 5px auto;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}