.scoreboard-container{
    background-color: #E6DCED;
    height: 100vh;
    max-width: 500px;
    margin: auto;
}

.scoreboard-bg{
    background-color: #E6DCED;
}
.bd{
    border: 1px solid black;
}

.flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.grid{
    display: grid;
    /* grid-column: 3; */
    grid-template-columns: auto auto ;
    /* grid-template-rows: auto auto auto; */
    justify-content: space-between;
    position: relative;
}

.padding-2{
    padding: 8px 20px;
}

.header-text{
    font-size: large;
    font-weight: 600;
    color: white;
    letter-spacing: 0.1rem;
    background: #481B68;
}

.content-text{
    color: black;
    font-size: large;
    font-weight: 600;
}

.content-center{
    position: absolute;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
}