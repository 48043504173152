*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    font-size: 1.1rem;
    font-family: 'Akshar', sans-serif;
    color: #4a4a4a;
    font-weight: 400;
    line-height: 1.5;
}

/* .theme-bg {
    background-color: #930f7f; 
} */

.container {
    max-width: 500px;
    margin: 0 auto;
    flex-grow: 1;
    position: relative;
    width: auto;
}
.section {
    padding: 3rem 1.5rem;
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    /* background-image: linear-gradient(rgba(245, 246, 252, 0.52), rgba(117, 19, 93, 0.73)),url("../themeimg/assets/quizBg.jpeg"); */
}
.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0.75rem;
}

.has-text-black{
    color: black ;
}

.has-text-white {
    color: white !important;
}
.has-text-centered {
    text-align: center !important;
}

.title {
    font-weight: 600;
    line-height: 1.125;
    font-size: 1.25rem;
    word-break: break-word;
}
h5 {
    display: block;
    font-size: 0.83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0.5rem;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


a {
    cursor: pointer;
    text-decoration: none;
}
.button {
    border-color: #dbdbdb;
    border-width: 1px;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    text-align: center;
    white-space: nowrap;
    font-size: 1rem;
    border-radius: 20px;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
    height: 2.5em;
    width: 80%;
}
.button.is-warning {
    background-color: #9C328C;
    border-color: transparent;
    color: white;
    font-weight: 800;


}
.button.is-fullwidth {
    display: flex;
}
.m-t-10 {
    margin-top: 10px !important;
}
.button.is-unsub{
    background-color: #9C328C;
    border-color: transparent;
    color: white;
    font-weight: 800;
    display: flex;
    justify-content: center;
    width: 60%;
}
