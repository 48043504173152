
.contest-play-button .submit-btn{
    width: 80%;
}
.back-btn-quiz{
    margin-top: -10px;
    width:max-content;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    padding: auto;
    position: relative;
    cursor: pointer;
    z-index: 100;
}
.back-btn-quiz:hover{
    cursor: pointer;
}

.upper-yellow-bg h5{
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 0.4em;
    height: fit-content;
}
.card-height{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height:fit-content;
    padding: 20px 5px !important;
    color: #4a4a4a;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
}
.end-of-que-class {
    font-size: 20px;
}
.question-section .question-options{
    margin-top: 1em;
}
.question-options{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.question-options .que-option-container{
    border: 1px solid #b5b3b3;
    width: 100%;
    display: flex;
    margin: 0.5em 0;
    padding: 0.3em 0;
    border-radius: 4px;
}
.question-options .que-option-container {
    /* padding-left: 15px; */
    padding-top: 14px;
    padding-bottom: 14px;
}
.question-options .que-option-container  label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;


}

.timer-container {
    padding: 2px; /* For example */
  background-color: #9D338D; /* Background color */
  border-radius: 50px; /* Rounded corners for the timer */
  color: white; /* Color of the timer text */
  font-weight: bold; /*
  }
  
  .timer {
    color: white; /* Timer text color */
    font-size: 0.8em; /* Timer text size */
    font-weight: bold;
  }