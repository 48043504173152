.alert-container{
    width: 90%;
    margin-top:2em;
    margin-left: auto;
    margin-right: auto;
    min-height: 2.5em;
}
.alert-box{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: inherit;
    color: white;
}
.alert-yellow {
    color: yellow;
}
.alert-red {
    background-color: red;
}
.alert-green {
    background-color: #53ca4b;
}
.alert-icon{
    width: 1em;
    padding: 0 2em;
}
