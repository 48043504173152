
.contest-play-button .submit-btn{
    width: 80%;
}
.back-btn-quiz{
    margin-top: -10px;
    width:max-content;
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    padding: auto;
    position: relative;
    cursor: pointer;
    z-index: 100;
}
.back-btn-quiz:hover{
    cursor: pointer;
}

.upper-yellow-bg h5{
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 0.4em;
    height: fit-content;
}
.card-height{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height:fit-content;
    padding: 20px 5px !important;
    color: #4a4a4a;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.25;
    width: 90%;
}
.end-of-que-class {
    font-size: 20px;
}
.question-section .question-options{
    margin-top: 1em;
}
.question-options{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.question-options .que-option-container{
    /* border: 1px solid black; */
    /* padding: 4px 0px; */
    width: 100%;
    display: flex;
    margin: 0.5em 0;
    padding: 0.3em 0;
    border-radius: 4px;
    background-color: #F0ECE9;
}
.question-options .que-option-container {
    /* padding-left: 15px; */
    padding-top: 8px;
    padding-left: 15px;
    padding-bottom: 8px;
}
.question-options .que-option-container  label{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;


}

