.background-theme{
    height: 100vh;
}
.upper-yellow-bg{
    /* background-color: #607AF1; */
    height: 72px;
    /* padding-top: 0.6em; */
}
.upper-yellow-bg > h5{
    font-weight: 600;
    font-size: 20px;
}
/* .contest-container{

}
.dreamtravel-heading{

} */
.bottom-white-bg{
    /* border: 2px solid white; */
    height: 100vh;
    /* background-color: #fff; */
    position: relative;
}
.card{
width: 95%;
   height: fit-content;
   min-height: 50vh;
   /* margin-top: -14vh; */
   margin-left: auto;
   margin-right: auto;
   border-radius: 18px;
   background-color: white;
   box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);

}
.card-container {
   padding: 10px;
}
.card-container  .card-head{
    font-size: 35px;
    /* padding: 10px 0; */
    font-weight: 700;
    color: #481B68;
}
.card-center-div{
    font-size: 20px;
    padding: 10px 0;
}
.card-center-div .inner-data{
    font-size: 1em;
}
.card-center-div img{
    max-height: 100px;
    width: inherit;
}
.card .card-container .card-para{
    font-size: 0.9em;
    padding: 10px 0;
}
.card .card-container .card-last-sec{
    font-size: 1em;
    color: #482D54;
    text-align: center;
    font-weight: 600;
    /* text-decoration: underline; */

}
.contest-play-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5em;
}

.contest-play-button .play-btn {
    background-color: #930f7f;
    border-radius: 2rem;
    color: white;
    font-weight: bold;
}
